// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-source-sans-pro"

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/main.css"
import "./src/styles.scss"

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    [
      `Le site a été mis à jour, `,
      `voulez-vous recharger la page pour avoir la dernière version?`,
    ].join(""),
  )

  if (answer === true) window.location.reload()
}
